@font-face {font-family: "Minion-Regular";
    src: url("assets/fonts/MinionPro-Regular.otf");
}
@font-face {font-family: "Overpass-Regular";
    src: url("assets/fonts/Overpass-Regular.ttf");
}
@font-face {font-family: "Overpass-ExtraBold";
    src: url("assets/fonts/Overpass-ExtraBold.ttf");
}
@font-face {font-family: "Overpass-Light";
    src: url("assets/fonts/Overpass-Light.ttf");
}
@font-face {font-family: "Lobster";
    src: url("assets/fonts/Lobster.otf");
}
@font-face {font-family: "Flama";
    src: url("assets/fonts/FlamaCondensed-Book.otf");
}
@font-face {font-family: "Flama-medium";
    src: url("assets/fonts/FlamaCondensed-Medium.otf");
}
@font-face {font-family: "MUI";
    src: url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
}

